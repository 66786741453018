// @ts-ignore
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  Box,
  InputAdornment,
  IconButton,
  Link,
  Grid,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Formik } from "formik";
import { SquareLock02Icon, UserIcon, ViewIcon, ViewOffSlashIcon } from "hugeicons-react";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { ROLE } from "../../constants";
import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const router = useRouter();
  const { signIn, signOut } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    // Sign out connected user when arrive in this page
    signOut();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const user = await signIn(values.email, values.password);
          if (user) {
            switch (user.role) {
              case ROLE.ADMIN:
                router.push("/admin/prospects");
                break;
              case ROLE.ADMIN_OFA:
                router.push("/admin/prospects");
                break;
              case ROLE.ADMIN_OA:
                router.push("/admin/insurances");
                break;
              case ROLE.GUEST:
                router.push("/auth/register?step=0");
                break;
              default:
                router.push("/dashboard");
                break;
            }
          }
        } catch (error) {
          const message = error.message || "Email ou mot de passe incorrect";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <TextField
                type="email"
                name="email"
                label="Email"
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <UserIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type={showPassword ? "text" : "password"}
                name="password"
                label="Mot de passe"
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                sx={{ my: 4 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <ViewOffSlashIcon /> : <ViewIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <SquareLock02Icon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} sx={{ py: 3, px: 12 }}>
                SE CONNECTER
              </Button>
            </Grid>
            {/*
            <Grid item xs={12} textAlign="center" sx={{ pt: 2 }}>
              <Link href="/auth/reset/enter-email">Mot de passe oublié</Link>
            </Grid>
            */}
            <Grid item xs={12} sx={{ pt: 12 }}>
              <Box sx={{ textAlign: "center" }} mt={6}>
                Pas encore de compte ?{" "}
                <Link href="/auth/sign-up" variant="body2" color="secondary">
                  Obtenir un accès
                </Link>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
