import styled from "@emotion/styled";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import Image from "next/image";
import React from "react";
import { Helmet } from "react-helmet-async";

// @ts-ignore
import Background from "../../public/static/img/bg.png";
// @ts-ignore
import Logo from "../../public/static/img/logo_white.png";
import SignInComponent from "../components/auth/SignIn";
import AuthLayout from "../layouts/Auth";

const Wrapper = styled(Grid)`
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ItemText = styled(Grid)`
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  background: url(${Background.src}),
    linear-gradient(
      310deg,
      ${(props) => props.theme.palette.secondary.main} 0%,
      ${(props) => props.theme.palette.primary.main} 50%
    );
  background-position: left bottom;
  background-size: contain;
  background-repeat: no-repeat;
`;

const ItemForm = styled(Grid)`
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  background: #fff;
`;

const StyledLogo = styled(Box)`
  margin: 0 0 30px 24px;
`;

function SignIn() {
  return (
    <Wrapper container spacing={0}>
      <Helmet title="Connexion" />
      <ItemText item xs={12} md={7}>
        <Box padding={8}>
          <StyledLogo>
            <Image src={Logo} width={220} height={85} alt="Logo Majors" />
          </StyledLogo>
          <Box paddingLeft={10}>
            <Typography
              component="h1"
              variant="h1"
              gutterBottom
              color="#fff"
              sx={{ fontSize: "2rem", marginBottom: 0 }}
            >
              L'outil financier complet pour les CGP
            </Typography>
            <Typography component="h2" variant="h4" color="#fff">
              <ul>
                <li>Génération automatique des DER, lettre de mission, rapport d’adéquation ;</li>
                <li>KYC, recueil patrimonial &amp; profil risque;</li>
                <li>Signature électronique;</li>
                <li>Coffre-fort sécurisé ;</li>
                <li>CRM intuitif ;</li>
                <li>Suivi ESG des portefeuilles ;</li>
                <li>Intelligence artificielle ;</li>
                <li>Simulateurs.</li>
              </ul>
            </Typography>
          </Box>
        </Box>
      </ItemText>
      <ItemForm item xs={12} md={5} sx={{ background: "#fff" }}>
        <Box padding={8} sx={{ maxWidth: 400 }}>
          <Typography
            component="h1"
            variant="h4"
            color="primary"
            gutterBottom
            sx={{ pt: 8, pb: 2, textAlign: "center" }}
          >
            CONNEXION
          </Typography>

          <SignInComponent />
        </Box>
      </ItemForm>
    </Wrapper>
  );
}

SignIn.getLayout = function getLayout(page) {
  return <AuthLayout>{page}</AuthLayout>;
};

export default SignIn;
