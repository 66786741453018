import styled from "@emotion/styled";
import { CssBaseline } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

import GlobalStyle from "../components/GlobalStyle";

const Root = styled("div")`
  height: 100%;
`;

const Auth = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
      <Outlet />
    </Root>
  );
};

export default Auth;
